<template>
    <div class="z-index-2 cursor-ponter" @click="openMemberPage">
        <div class="fl-y fl-a-c bg-white py-3 bb-1-primary h-100 px-3" :class="{'w-80':size ==='sm'}">
<!--            <a :href="type === 'board'?'/about/#ourBoard': '/our-history/#ourFounders'" class="ml-auto mt-0 top-0 cursor-ponter" v-if="size === 'lg'" @click="goBack">-->
            <a :href="type === 'board'?'/about/#ourBoard': '/our-history/#ourFounders'" class="ml-auto mt-0 top-0 cursor-ponter" v-if="size === 'lg'">
                <span class="p-2 bg-primary"><i class="fa fa-chevron-left"></i></span>
            </a>

            <img :src="img" alt="" class="border-rounded mb-2 img-fluid"
                 :class="{'half-width':size ==='sm','w-12':size === 'lg'}">
            <p class="font-weight-bold" m-0>{{ name }}</p>
            <p class="font-weight-light m-0 text-center" :class="{'min-h-60p':!$slots.details}">{{ designation }}</p>
            <div>
                <slot name="details"></slot>
            </div>
            <div>
                <slot name="footer"></slot>
            </div>
        </div>

    </div>

</template>

<script>
export default {
    name: 'PersonInfoCard',
    props: {
        name: {
            type: String,
            default: ''
        },
        designation: {
            type: String,
            default: ''
        },
        img: {
            type: Image

        },
        size: {
            type: String,
            default: 'sm'
        },
        type: {
            type: String,
            default: ''
        }
    },
    methods: {
        goBack () {
            // this.$router.go(-1);
            if (this.type === 'board') {
                this.$router.push({ name: 'About' });
            } else {
                this.$router.push({ name: 'History' });
            }
            // const ourFoundersEl = document.querySelector('#our-founders-s');
            // console.log('our founders', ourFoundersEl);
            // window.scrollTo(500, ourFoundersEl.offsetTop - 50);
        },
        openMemberPage () {
            if (this.size === 'sm' && this.type === 'board') {
                const routePath = '/board-member/' + this.name.replace(/\s+/g, '');
                this.$router.push({ path: routePath, params: { name: this.name.trim() } });
            } else if (this.size === 'sm' && this.type === 'founder') {
                const routePath = '/founder-info/' + this.name.replace(/\s+/g, '');
                this.$router.push({ path: routePath, params: { name: this.name.trim() } });
            }
        }
    }
};
</script>

<style scoped>
.border-rounded {
    border-radius: 50%;
}

.bb-1-primary {
    border-bottom: 0.3em solid var(--color-primary);
}

.h-100 {
    height: 100%;
}
.min-h-60p{
    min-height: 3.75rem;
}
</style>
