<template>
    <section class="bg-1 py-7 bg-img">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-center font-weight-bold mt-3 mb-5">Our Values</h2>
                </div>
                <our-values-card class="col-12 col-lg-6" :class="{'mb-3':i< items.length - 3}"
                                 v-for="(m, i) in items" :key="i"
                                 :heading="m.name" :img="m.img" :content="m.content"></our-values-card>
            </div>
        </div>
    </section>
</template>

<script>
import OurValuesCard from '@/views/components/UI/OurValuesCard';
import Img1 from '../../../assets/img/values/1.png';
import Img2 from '../../../assets/img/values/2.png';
import Img3 from '../../../assets/img/values/3.png';
import Img4 from '../../../assets/img/values/4.png';
import Img5 from '../../../assets/img/values/5.png';
import Img6 from '../../../assets/img/values/6.png';
import Img7 from '../../../assets/img/values/7.png';
import Img8 from '../../../assets/img/values/8.png';

export default {
    name: 'OurValues',
    components: { OurValuesCard },
    data () {
        return {
            items: [
                {
                    name: 'FAITH',
                    content: 'Faith is the power that lights up our darkest paths, keeps us humble and sensitive to human struggle, and gives us the conviction to pursue our honest intentions to serve society. The blessings of God give us the strength to persist in our dream of an equal world.',
                    img: Img1
                },
                {
                    name: 'INTEGRITY',
                    content: 'The moral fiber is to do what is right with the utmost honesty. We often end up making the tougher choices because we opt to do the very best for the people we are serving.',
                    img: Img2
                },
                {
                    name: 'TRANSPARENCY',
                    content: 'We call a spade a spade. We prefer our results to speak for us. We do not over ambitiously project our accomplishments to gain favor or glorify our success.',
                    img: Img3
                },
                {
                    name: 'INNOVATION',
                    content: 'The ESAF team tries to look for novel solutions for problems each time to factor in the unique circumstances, limitations, and\n' +
                        '\n' +
                        'opportunities.',
                    img: Img4
                },
                {
                    name: 'EMPATHY',
                    content: 'Our guiding light for bringing hope into the lives of communities facing adversities. It doesn’t take much to see the pain behind the blank eyes, the frustration, and struggle behind the frown, or the weight of hopelessness in the drooping shoulders. It just takes a compassionate heart and some empathy.',
                    img: Img5
                },
                {
                    name: 'ACCOUNTABILITY',
                    content: 'To empower the poor and the marginalized, especially women and children through access to education, finance, health services, natural resources, marketing, and technology and provide them opportunities for sustainable development and holistic transformation.',
                    img: Img6
                },
                {
                    name: 'INCLUSION',
                    content: 'A work environment of teamwork that values inclusion, diversity, and equity in participation. A culture in which every person, is genuinely\n' +
                        'valued and to be treated with dignity and respect. Everyone is to be able to belong and participate fully in breaking the barriers of\n' +
                        'systematic discrimination of wage, gender, ethnicity, religion, or culture. Men and women are to be equal to be included in the\n' +
                        'leadership roles of the organization.',
                    img: Img7
                },
                {
                    name: 'COMPETENCE',
                    content: 'As a team we will be passionate to keep unity, achieving the highest standards of quality, always promoting maximum impact. Keeping\n' +
                        '\n' +
                        'our vision in focus, to carry out our mission with competence, diligence, and excellence.',
                    img: Img8
                }
            ]
        };
    }
};
</script>

<style scoped>
section {
    position: relative;
}

.bg-img {
    background-image: url("../../../assets/img/bg/bg-09.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
</style>
