<template>
    <section class="bg-1 py-7">
        <img src="../../../assets/img/layers/layer-06.png" alt="#" class="layout-bg left h-lg-50">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-center font-weight-bold mb-5">Our Board</h2>
                </div>
                <person-info-card type="board" class="col-12 col-lg-4 fl-x-cc"
                                  :class="{'mb-5':i< members.length - members.length % 3}"
                                  v-for="(m, i) in members" :key="i"
                                  :name="m.name" :img="m.img" :designation="m.desig">
                    <template #footer>
                        <router-link :to="{path:'/board-member/' + m.name.replace(/\s+/g, '')}"
                                     class="text-primary text-decoration-none font-weight-semibold mt-4">Read More
                        </router-link>
                    </template>
                </person-info-card>
            </div>
        </div>
    </section>
</template>

<script>
import PersonInfoCard from '@/views/components/UI/PersonInfoCard';
import personImg1 from '../../../assets/img/board/04.jpg';
import personImg2 from '../../../assets/img/board/06.jpg';
// import personImg3 from '../../../assets/img/board/07.jpg';
import personImg3 from '../../../assets/img/board/ajith-sen.jpg';
import personImg4 from '../../../assets/img/board/011.jpg';
import personImg5 from '../../../assets/img/board/eldon-gamble-chairman.jpg';
import personImg6 from '../../../assets/img/board/08.jpg';
// import placeholderImg from '../../../assets/img/placeholder/placeholder.png';

export default {
    name: 'OurBoard',
    components: { PersonInfoCard },
    data () {
        return {
            members: [
                {
                    name: 'J. ELDON GAMBLE - CANADA',
                    desig: 'Board Chairman',
                    img: personImg5
                },
                {
                    name: 'BEENA GEORGE - INDIA',
                    desig: 'Chief People Officer, ESAF, Co-operative',
                    img: personImg4
                },
                {
                    name: 'ALLAN WILLMS - CANADA',
                    desig: 'Treasurer',
                    img: personImg6
                },
                {
                    name: 'DR. AJITHSEN SELVADHAS - INDIA',
                    desig: 'Vice President: Cedar Retail Pvt Ltd , Associate Director: ESAF Foundation',
                    img: personImg3
                },
                {
                    name: 'SAJI GEORGE - CANADA',
                    desig: 'Program Director',
                    img: personImg2
                },
                {
                    name: 'BIJILA G. - CANADA',
                    desig: 'Chief Executive Officer',
                    img: personImg1
                }
            ]
        };
    }
};
</script>

<style scoped lang="scss">
section {
    position: relative;
}

.h-lg-50 {
    height: 40%;
    @media (min-width: 992px) {
        height: 50%;
    }
}
</style>
