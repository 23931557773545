<template>
    <section class="bannner-about bg-about-banner">
        <img src="../../../assets/img/layers/layer-04.png" alt="#" class="layout-bg-r">
        <!--        <img src="../../../assets/img/bg/bg-13.png" alt="#" class="layout-bg-l">-->
        <!--        <img src="../../../assets/img/Maple_Leaf.png" alt="#" class="">-->
        <br>
        <div class="container cont-height mb-md-5 mb-lg-0">
            <div class="row text-white">
                <div class="col-lg-6 col-xl-6 text-center text-lg-left">
                    <img src="../../../assets/img/bg/bg-14.png" class="mb-4" alt="#">
                    <h2 class="font-weight-semibold text-white mb-4">
                        Our Vision
                    </h2>
                    <p>Sustainable holistic transformation of the poor and the
                        marginalized resulting in equitable, dignified society globally.</p>
                    <h2 class="font-weight-semibold text-white mb-4">
                        Our Mission
                    </h2>
                    <p>To empower the poor and the marginalized especially women,
                        children, and youth by providing them access to education,
                        finance, health services, natural resources, marketing, and
                        technological support and to explore the fullest human
                        potential by creating opportunities for sustainable
                        development and holistic transformation.</p>

                </div>
                <div class="col-lg-6 col-xl-6 text-center">
                    <div class="fl-x fl-x-cc h-100 d-sm-none-lg-fl">
                        <img src="../../../assets/img/Maple_Leaf.png" alt="#" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'VisionAndMissionBanner'
};
</script>

<style scoped lang="scss">
section {
    position: relative;
}

.bannner-about {
    min-height: calc(58px - 100vh);
    background-repeat: no-repeat;
    //background-size: contain;
    background-image: url("../../../assets/img/bg/bg-07.png");
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: 75% 0%;
    line-height: 2rem;
    @media (min-width: 992px) {
        min-height: calc(84px - 100vh);
    }
    //@media (max-width: 1024px) {
    //    background-position: 80% 76px;
    //}
    h2 {
        font-size: 2.5rem;
    }

    @media (max-width: 992px) {
        align-items: center;
        h2 {
            font-size: 1.8rem;
            line-height: unset;
        }
    }

    @media (min-width: 992px) {
        .bottom-section {
            position: absolute;
            bottom: 40px;
            left: 15px;
        }
        .content {
            margin-top: -84px !important;
        }
    }

    table {
        td {
            padding-right: 15px;
        }
    }

    .cont-height {

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: var(--color-primary);
        padding: 2rem 1rem;
        @media (min-width: 992px) {
            background-color: transparent;
            min-height: calc(100vh - 84px);
        }
    }

}

</style>
