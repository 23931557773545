<template>
    <section class="py-7">
        <img src="../../../assets/img/layers/layer-01.png" alt="" class="layout-bg-flow z-index-2">
        <div class="overlay z-index--1"></div>
        <div class="container z-index-4">
            <h2 class="font-weight-bold text-center text-white mb-5">Our Strategy</h2>
            <div class="row">
                <div class="col-12 col-lg-6 mb-3 order-lg-1">
                    <div class="glass-panel h-100">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <!--                            <btn color="primary" text="EASF GLOBAL CANADA"></btn>-->
                                <div class="fl-x fl-j-c fl-a-c">
                                    <div class="half-width bg-primary p-3 bs-1 text-center">ESAF GLOBAL CANADA</div>
                                </div>
                            </div>
                            <div class="col-12 mb-2">
                                <div class="text-center">
                                    <i class="fa fa-arrow-down fa-3x"></i>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="row">
                                    <div class="col-12 col-lg-4 mb-2">
                                        <div class="b-1 bg-white bs-1 text-center p-3 h-100 fl-x-cc fs--1">
                                            Community organization
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-2">
                                        <div class="b-1 bg-white bs-1 text-center p-3 h-100 fl-x-cc fs--1">
                                            Partnership & Networking
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-2">
                                        <div class="b-1 bg-white bs-1 text-center p-3 h-100 fl-x-cc fs--1">
                                            Training & Mentoring
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="text-center">
                                    <i class="fa fa-arrow-down fa-3x"></i>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="row">
                                    <div class="col-12 col-lg-4 mb-2">
                                        <div class="b-1 bg-white bs-1 text-center p-3 h-100 fl-x-cc fs--1">
                                            Education
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-2">
                                        <div class="b-1 bg-white bs-1 text-center p-3 h-100 fl-x-cc fs--1">
                                            Skill Development & Livelihood Opportunities
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-2">
                                        <div class="b-1 bg-white bs-1 text-center p-3 h-100 fl-x-cc fs--1">
                                            Basic Health Care
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="text-center">
                                    <i class="fa fa-arrow-down fa-3x"></i>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="bg-primary p-3 text-center">
                                    <b>A Sustainable Community</b> : increased skill development and livelihood
                                    opportunities, basic health care provision, and educational advancement.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-lg-6 order-lg-0 ">
                    <p class="text-white">
                        The divide between the “developed world” and “the third world” continues
                        to grow across our globe. The increasing gap between the “haves” and “have
                        nots” is challenging the world more aggressively today than at any time in
                        history. Sadly, basic rights such as food, education, and healthcare are not
                        available to a large part of many societies around the world today, resulting
                        in a lack of sustainability, livelihood, and dignity.
                        <br><br>
                        Bridging this gap is not an easy job. ESAF GLOBAL - CANADA exists to tackle
                        that challenge head-on by ensuring resources are shared, new opportunities
                        are created, and knowledge exchanged to provide innovative and
                        sustainable solutions to the “have not” societies and villages of the world.
                        ESAF GLOBAL - CANADA aspires to be the connecting force that brings hope
                        to the less privileged through the creation of skill development and livelihood
                        opportunities, education, and basic health care. The advancement of
                        information technology in the past two decades has gifted the world with a
                        new concept that is so endearing to hear - “Global Village”. ESAF GLOBAL -
                        CANADA will utilize international resources through the use of technology to
                        help needy villages enhance their way of life around the world.
                        <br><br>
                        ESAF’s journey over the last three decades has been both impactful and
                        packed with learning. ESAF has successfully developed innovative programs
                        to benefit the ethnic and various people groups in parts of India. While a
                        great deal of work is still required in India, ESAF recognizes that its
                        groundbreaking programs and initiatives could be implemented
                        internationally around the world. As a result, ESAF GLOBAL – CANADA was
                        born, to lead the proliferation of driving sustainability in the neediest
                        international various people groups, through engagement, empowerment,
                        and the exchange of knowledge.
                    </p>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'OurStrategySection'
};
</script>

<style lang="scss" scoped>
section {
    position: relative;
    background: url('../../../assets/img/bg/bg-05.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.overlay {
    position: absolute;
    top: 0;
    width: 100%;
    /*height: 100%;*/
    height: 100%;
    background-color: #010306bd;
    z-index: 0;
}

.layout-bg-flow {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    @media (min-width: 992px) {
        width: 20%;
    }
}

.glass-panel {
    color: #fff;
    /*margin: 40px auto;*/
    background-color: rgba(255, 255, 255, 0.06);
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    border-radius: 15px;
    padding: 32px;
    backdrop-filter: blur(10px);
}
</style>
