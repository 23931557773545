import { render, staticRenderFns } from "./OurStrategySection.vue?vue&type=template&id=8e47c540&scoped=true&"
import script from "./OurStrategySection.vue?vue&type=script&lang=js&"
export * from "./OurStrategySection.vue?vue&type=script&lang=js&"
import style0 from "./OurStrategySection.vue?vue&type=style&index=0&id=8e47c540&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e47c540",
  null
  
)

export default component.exports