<template>
    <div class="about">
        <section id="VisionAndMission">
            <vision-and-mission-banner></vision-and-mission-banner>
        </section>
        <section id="ourValues">
            <our-values></our-values>
        </section>
        <section id="ourStrategy">
            <our-strategy-section></our-strategy-section>
        </section>
        <section id="ourBoard">
            <our-board></our-board>
        </section>
    </div>
</template>
<script>

// import InnerBanner from '@/views/components/InnerBanner';
import OurStrategySection from '@/views/components/AboutUs/OurStrategySection';
import OurBoard from '@/views/components/AboutUs/OurBoard';
import VisionAndMissionBanner from '@/views/components/AboutUs/VisionAndMission';
import OurValues from '@/views/components/AboutUs/OurValuesSection';

export default {
    name: 'About',
    components: { VisionAndMissionBanner, OurBoard, OurStrategySection, OurValues }
};
</script>

<style scoped>

</style>
