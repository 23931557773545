<template>
    <div>
        <div class="fl-x w-40 fl-a-c">
            <img :src="img" alt="" class="mr-2 half-width">
            <p class="font-weight-bold">{{ heading }}</p>
        </div>
        <p style="margin-left: 8px">{{ content }}</p>
    </div>

</template>

<script>
export default {
    name: 'OurValuesCard',
    props: {
        heading: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        img: {
            type: Image

        }
    }
};
</script>

<style scoped>
.border-rounded {
    border-radius: 50%;
}

.bb-1-primary {
    border-bottom: 0.3em solid var(--color-primary);
}
</style>
