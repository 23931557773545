import { render, staticRenderFns } from "./PersonInfoCard.vue?vue&type=template&id=531dda71&scoped=true&"
import script from "./PersonInfoCard.vue?vue&type=script&lang=js&"
export * from "./PersonInfoCard.vue?vue&type=script&lang=js&"
import style0 from "./PersonInfoCard.vue?vue&type=style&index=0&id=531dda71&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531dda71",
  null
  
)

export default component.exports